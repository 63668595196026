import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mini-app"
export default class extends Controller {
  static values = {
    isNeedBackButton: Boolean
  }
  static targets = [
    "signInForm",
    "telegramData",
  ]

  connect() {
    window.Telegram.WebView.postEvent("web_app_set_header_color", () => {}, { color: "#ffffff" })
    this.#initBackButton()
  }

  requestContact(event) {
    event.preventDefault()
    window.Telegram.WebApp.requestContact(this.#onContactRequested.bind(this))
  }

  #onContactRequested(isUserSentContact, user) {
    if (!isUserSentContact) return
    this.telegramDataTarget.value = user.response
    this.signInFormTarget.submit()
  }

  #initBackButton() {
    if (this.isNeedBackButtonValue) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(function() {
        history.back()
      });
    } else {
      Telegram.WebApp.BackButton.hide();
    }
  }
}
